import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const RedirectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
  padding: 20px;
`;

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  margin: 20px auto;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #2b368b; // Using Indigo's primary color
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const Message = styled.p`
  color: #666;
  margin: 8px 0;
`;

interface DeepLinkRedirectProps {
  path: string;
}

const STORE_URLS = {
  iOS: 'https://apps.apple.com/app/6670152929',
  Android:
    'https://play.google.com/store/apps/details?id=com.indigo.indigouser',
};

const DeepLinkRedirect: React.FC<DeepLinkRedirectProps> = ({ path }) => {
  const [storeText, setStoreText] = useState('');

  useEffect(() => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const storeUrl = isIOS ? STORE_URLS.iOS : STORE_URLS.Android;
    setStoreText(
      isIOS
        ? 'Redirecting to App Store...'
        : 'Redirecting to Google Play Store...',
    );

    // Try to open the app
    window.location.href = `indigogrouperp://${path}`;

    // Set timeout for store redirect
    const timer = setTimeout(() => {
      window.location.href = storeUrl;
    }, 2000);

    return () => clearTimeout(timer);
  }, [path]);

  return (
    <RedirectContainer>
      <Title>Opening Indigo Group App</Title>
      <Spinner />
      <Message>
        If the app doesn't open automatically, you'll be redirected to download
        it in a moment...
      </Message>
      <Message>{storeText}</Message>
    </RedirectContainer>
  );
};

export default DeepLinkRedirect;
